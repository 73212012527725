import { createStore } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import router from '@/router'
import * as Sentry from '@sentry/vue'

moment.locale('it')

// ---INITIAL STATE
const getDefaultState = () => {
  return {
    user: null,
    userData: null,
    campaigns: null,
    localVersion: null,
    desiredVersion: null,
    carnetCode: null
  }
}

let loaded = false

const store = createStore({
  state: getDefaultState(),

  // ---GETTERS
  getters: {
    isAuthenticated: state => !!state.user,
    stateUser: state => state.user,
    stateCampaigns: state => state.campaigns,
    getDesiredVersion: (state) => {
      return state.desiredVersion
    },
    getLocalVersion: (state) => {
      return state.localVersion
    },
    getCarnet (state) {
      return state.carnetCode
    }
  },

  // ---MUTATIONS
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUser (state, user) {
      state.user = user
      if (user) localStorage.setItem('store_' + process.env.VUE_APP_NAME, JSON.stringify(user))
      else localStorage.removeItem('store_' + process.env.VUE_APP_NAME)
    },
    setUserData (state, user) {
      state.userData = user
    },
    setCampaigns (state, camps) {
      state.campaigns = camps
    },
    setCarnet (state, carnet) {
      state.carnetCode = carnet
      localStorage.setItem('carnet_' + process.env.VUE_APP_NAME, JSON.stringify(carnet))
    },
    initializeStore (state) {
      if (!loaded) {
        if (localStorage.getItem('store_' + process.env.VUE_APP_NAME)) {
          state.user = JSON.parse(localStorage.getItem('store_' + process.env.VUE_APP_NAME))
        }
        loaded = true
      }
    },
    updateLocalVersion (state, version) {
      state.localVersion = version
    },
    updateDesiredVersion (state, version) {
      console.log('---Current Version: ', state.localVersion, ' ---Online Version: ', version)
      state.desiredVersion = version
    }
  },

  // ---ACTIONS
  actions: {
    resetStore ({ commit }) {
      commit('resetState')
    },
    // --Get website version
    async getWebsiteVersion () {
      return await axios.get('/utils/ui-version')
        .then((rsp) => {
          return rsp.data.ui_version
        })
        .catch((e) => {
          console.error('WEBSITE VER', e)
          Sentry.captureException(e)
          return false
        })
    },
    async getAgreements ({ state, commit }) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const result = await axios.get('/booking/blanket_agreements', {
        headers: {
          Authorization
        }
      }).then((rsp) => {
        return rsp.data
      }).catch((e) => {
        console.error('AGREEMENTS', e)
        Sentry.captureException(e)
        return false
      })

      commit('setCampaigns', result)
    },
    async getStatusOrder ({ state }, id) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const result = await axios.get('/booking/shipment_status/' + id, {
        headers: {
          Authorization
        }
      }).then((rsp) => {
        // console.log('ORDER STATUS', rsp.data, id)
        return rsp.data
      }).catch((e) => {
        console.error('SINGLE AG', e)
        Sentry.captureException(e)
        return false
      })

      return result
    },
    async getSingleAgreement ({ state }, agNo) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const result = await axios.get('/booking/blanket_agreement/' + agNo + '/lines', {
        headers: {
          Authorization
        }
      }).then((rsp) => {
        return rsp.data
      }).catch((e) => {
        console.error('SINGLE AG', e)
        Sentry.captureException(e)
        return false
      })

      return result
    },
    async getCampaignAlternates ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const result = await axios.get('/booking/alternate_cat_nums/' + payload.agreement_no + '/' + payload.card + '/', {
        headers: {
          Authorization
        }
      }).then((rsp) => {
        return rsp.data
      }).catch((e) => {
        console.error('ALTERNATES', e)
        Sentry.captureException(e)
        return false
      })

      return result
    },
    async getCampaignPVs ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const result = await axios.get('/points_of_sale/' + payload.agreement_no + '/' + payload.bp_code, {
        headers: {
          Authorization
        }
      }).then((rsp) => {
        // console.log('PVS', rsp)
        return rsp.data
      }).catch((e) => {
        console.error('PVs', e)
        Sentry.captureException(e)
        return false
      })

      return result
    },
    // --Export Orders
    async exportOrders ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/booking/export/' + payload.agreement, { recipients: [payload.email] }, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---BOOKING', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('EXPORT ORDERS', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking
    async submitOrder ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/booking/', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---BOOKING', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('BOOKING', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking history
    async getPastOrders ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const query = {}
      if (payload.loyalty_card) query.loyalty_card = payload.loyalty_card
      if (payload.full_name) query.full_name = payload.full_name
      if (payload.pdv_id) query.pdv_id = payload.pdv_id
      if (payload.item_id) query.item_id = payload.item_id
      if (payload.from) query.from_date = payload.from
      if (payload.to) query.to_date = payload.to

      query.skip = payload.skip
      query.limit = payload.limit

      // if (payload.limit && !payload.loyalty && !payload.full_name && !payload.pdv_id && !payload.item_id && !payload.from && !payload.to) {
      //   query.limit = payload.limit
      // } else {
      //   query.limit = 99999
      // }
      // if (payload.skip && !payload.loyalty && !payload.full_name && !payload.pdv_id && !payload.item_id && !payload.from && !payload.to) {
      //   query.skip = payload.skip
      // } else {
      //   query.skip = 0
      // }
      const rsp = await axios.post('/booking/' + payload.agreement, query, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---HISTORY', rsp)
          return rsp.data
        })
        .catch((e) => {
          console.error('HISTORY', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking history (from DATE to DATE)
    async getFilteredPastOrdersFromTo ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/booking/betweendates/' + payload.agreement + '/' + payload.from + '/' + payload.to, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          console.log('---HISTORY', rsp)
          return rsp.data
        })
        .catch((e) => {
          console.error('PAST filtered', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking history (NAME / SURNAME)
    async getFilteredPastOrdersNameSurname ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/booking/?agreement_no=' + payload.agreement_no + '&full_name=' + payload.full_name, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          console.log('---HISTORY', rsp)
          return rsp.data
        })
        .catch((e) => {
          console.error('PAST filtered', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking history (filtered by PV)
    async getFilteredPastOrdersByPv ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/booking/' + payload.agreement + '/' + payload.pdv_id + '?skip=' + payload.skip + '&limit=' + payload.limit, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---HISTORY', rsp)
          return rsp.data
        })
        .catch((e) => {
          console.error('PAST filtered', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Booking history (filtered by ID)
    async getFilteredPastOrdersById ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/booking/byitem/' + payload.agreement + '/' + payload.item_id + '?skip=' + payload.skip + '&limit=' + payload.limit, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---HISTORY', rsp)
          return rsp.data
        })
        .catch((e) => {
          console.error('PAST filtered', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Send Mail Report
    async sendMail ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/utils/export-items/', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---SEND MAIL', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('SEND MAIL', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Assign carnet to target operator
    async assignCarnet ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/assignments/', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---SEND MAIL', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('CARNET', e)
          Sentry.captureException(e)
          return e
        })
      return rsp
    },
    // --Read the whole carnet history
    async carnetHistory ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/assignments/' + '?skip=' + payload.skip + '&limit=' + payload.limit, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          // console.log('---SEND MAIL', rsp)
          return rsp
        })
        .catch((e) => {
          console.error('CARNET history', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Retrieve single carnet
    async filterByCarnet ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.get('/assignments/by_assignable_code/' + payload.carnet, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('CARNET single', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Resend Voucher
    async resendVoucher ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/booking/resend/' + payload.id, {}, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          console.log('---RESEND VOUCHER', rsp.data, payload.id)
          return rsp
        })
        .catch((e) => {
          console.error('RESEND VOUCHER', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Campaign Stats
    async getStats ({ state }) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        return await axios.get('/utils/stats', {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            return rsp.data
          })
          .catch((e) => {
            console.error('STATS', e)
            Sentry.captureException(e)
            return false
          })
      }
    },
    // --Login
    async logIn ({ commit }, data) {
      const rsp = await axios.post('/login/access-token', data)
        .then((rsp) => {
          commit('setUser', rsp.data)
          return true
        })
        .catch((e) => {
          console.error('LOGIN', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --User data
    async getUser ({ commit, state }) {
      const data = state.user
      if (data) {
        const Authorization = data.token_type + ' ' + data.access_token
        const rsp = await axios.get('/users/me', {
          headers: {
            Authorization
          }
        })
          .then((rsp) => {
            commit('setUserData', rsp.data)
            return rsp.data
          })
          .catch((e) => {
            commit('setUser', null)
            setTimeout(() => {
              router.push('/login')
            }, 200)
            console.error('USER', e)
            Sentry.captureException(e)
            return false
          })
        return rsp
      }
    },
    // --Registration confirm
    async confirmUser ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/users/activate', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('CONFIRM USER', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Recover password
    async recoverPassword ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      // console.log(payload)
      const rsp = await axios.post('/password-recovery/' + payload.email, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('RESET PASSWORD', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Reset password
    async resetPassword ({ state }, payload) {
      const data = state.user
      const Authorization = data.token_type + ' ' + data.access_token
      const rsp = await axios.post('/reset-password', payload, {
        headers: {
          Authorization
        }
      })
        .then((rsp) => {
          return rsp
        })
        .catch((e) => {
          console.error('RESET PASSWORD', e)
          Sentry.captureException(e)
          return false
        })
      return rsp
    },
    // --Logout
    logout ({ commit }) {
      commit('setUser', null)
    }
  },

  // ---MODULES
  modules: {}
})

store.subscribe((mutation, state) => {
  if (state.user) localStorage.setItem('store_' + process.env.VUE_APP_NAME, JSON.stringify(state.user))
  else localStorage.removeItem('store_' + process.env.VUE_APP_NAME)
})

export default store
