import {
  defineRule,
  configure
} from 'vee-validate'

import {
  email,
  confirmed,
  numeric,
  required,
  min,
  max
} from '@vee-validate/rules'

import { localize } from '@vee-validate/i18n'

// ---VENDOR
defineRule('email', email)
defineRule('confirmed', confirmed)
defineRule('numeric', numeric)
defineRule('required', required)
defineRule('min', min)
defineRule('max', max)

// ---CUSTOM
defineRule('req', value => {
  if (!value || !value.length) {
    return 'Questo campo è obbligatorio'
  }
  return true
})

defineRule('phone', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true
  }

  let trimmed = value.replace(/\s/g, '')
  trimmed = trimmed.replace('+', '')
  // Check if phone number
  if (isNaN(trimmed)) {
    return 'Numero di telefono non valido (esempio corretto: +39XXXXXXXXXX)'
  }
  return true
})

// ---TRANSLATIONS
configure({
  // Generates an Italian message locale generator
  generateMessage: localize('it', {
    messages: {
      numeric: 'Inserire solo valori numerici',
      email: 'Indirizzo non valido',
      confirmed: 'I due campi non coincidono',
      required: 'Questo campo è obbligatorio',
      min: 'Numero minimo non raggiunto',
      max: 'Numero massimo superato'
    }
  })
})
